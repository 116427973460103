const dev = {
  apiRoot: 'https://api.appli-intramuros.com',
  apiEvents: '/events/',
  apiCollectivites: '/locations/collectivites/',
  apiCategories: '/categories/',
  apiDirectories: '/services/directories/',
  apiNews: '/news/',
  apiAnecdote: '/anecdotes/',
  apiPoi: '/poi/',
  apiSurveys: '/services/survey/',
  apiSurveyResults: '/services/surveyresults/',
  apiSurveyResultsAggregated: '/services/surveyresults_aggregated/',
  apiSuggestbox: '/services/suggestbox',
  apiSuggestion: '/services/suggestions',
  apiLegalFiles: '/services/legal_files',
  apiTopics: '/locations/alert_category/',
  apiLocalActors: '/actors/generic/',
};

const staging = {
  apiRoot: 'https://staging.api.appli-intramuros.com',
  apiEvents: '/events/',
  apiCollectivites: '/locations/collectivites/',
  apiCategories: '/categories/',
  apiDirectories: '/services/directories/',
  apiNews: '/news/',
  apiAnecdote: '/anecdotes/',
  apiPoi: '/poi/',
  apiSurveys: '/services/survey/',
  apiSurveyResults: '/services/surveyresults/',
  apiSurveyResultsAggregated: '/services/surveyresults_aggregated/',
  apiSuggestbox: '/services/suggestbox',
  apiSuggestion: '/services/suggestions',
  apiLegalFiles: '/services/legal_files',
  apiTopics: '/locations/alert_category/',
  apiLocalActors: '/actors/generic/',
};

const prod = {
  apiRoot: 'https://api.appli-intramuros.com',
  apiEvents: '/events/',
  apiCollectivites: '/locations/collectivites/',
  apiCategories: '/categories/',
  apiDirectories: '/services/directories/',
  apiNews: '/news/',
  apiAnecdote: '/anecdotes/',
  apiPoi: '/poi/',
  apiSurveys: '/services/survey/',
  apiSurveyResults: '/services/surveyresults/',
  apiSurveyResultsAggregated: '/services/surveyresults_aggregated/',
  apiSuggestbox: '/services/suggestbox',
  apiSuggestion: '/services/suggestions',
  apiLegalFiles: '/services/legal_files',
  apiTopics: '/locations/alert_category/',
  apiLocalActors: '/actors/generic/',
};

let config = null;
console.log('api target env =' + process.env.TARGET_ENV);
if (process.env.TARGET_ENV === 'production') {
  config = prod;
} else if (process.env.TARGET_ENV === 'staging') {
  config = staging;
} else {
  config = dev;
}

export default {
  ...config,
};
