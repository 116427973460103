import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider, StyledEngineProvider, Grid, Typography } from '@mui/material';
import { orderBy, size, slice } from 'lodash';
import request from 'superagent';
import ReactPaginate from 'react-paginate';
import { muiTheme, defaultThemeOption } from './utils/tools';
import Loader from './utils/Loader';
import NewsCard from './components/NewsCard';
import NewsSlider from './components/NewsSlider';
import config from './config';
import { calculateNewsFilters, getNewsListFiltered } from './utils/calculation'
import SelectorNewsType from './utils/SelectorNewsType';

const NUMBER_OF_ITEMS_PER_PAGE =
  parseInt(window.itemPerPageNews, 10) > 0
    ? parseInt(window.itemPerPageNews, 10)
    : 10;

const DISPLAY_MODE = window.displayModeNews

class News extends Component {
  constructor(props) {
    super(props);
    this.state = { currentPage: 0, typeSelected: null };
  }

  componentDidMount() {
    const { cityId, aggloId, cityINSEE, aggloSIREN, excludeActors, intramurosTopicId, intramurosActorId } = this.props;
    if (cityId || aggloId || intramurosTopicId || intramurosActorId) {
      this.fetchNews(cityId, aggloId, excludeActors, intramurosTopicId, intramurosActorId);
    } else if (cityINSEE || aggloSIREN) {
      this.fetchNewsByCode(cityINSEE, aggloSIREN, excludeActors, intramurosTopicId, intramurosActorId);
    }
  }

  fetchNews(cityId, aggloId, excludeActors, topicsID, actorsID) {
    return request
      .get(config.apiRoot + config.apiNews)
      .query({ 'city-id': cityId || '' })
      .query({ 'agglo-id': aggloId || '' })
      .query({ 'exclude-actors': excludeActors ? '1' : '0' })
      .query({ 'topic-id': topicsID || '' })
      .query({ 'actors-id': actorsID || '' })
      .query({ source: 'widget' })
      .then((res) => res.body)
      .then((news) => {
        this.setState({
          news: orderBy(news, ['published_at'], ['desc']),
          fetched: true,
        });
      })
      .catch((err) => {
        this.setState({ error: true });
        console.log('error during call : ' + err);
      });
  }

  fetchNewsByCode(cityINSEE, aggloSIREN, excludeActors, topicsID, actorsID) {
    request
      .get(config.apiRoot + config.apiNews)
      .query({ 'city-insee': cityINSEE || '' })
      .query({ 'agglo-siren': aggloSIREN || '' })
      .query({ 'exclude-actors': excludeActors ? '1' : '0' })
      .query({ 'topic-id': topicsID || '' })
      .query({ 'actors-id': actorsID || '' })
      .query({ source: 'widget' })
      .then((res) => res.body)
      .then((news) => {
        this.setState({
          news: orderBy(news, ['published_at'], ['desc']),
          fetched: true,
        });
      })
      .catch((err) => {
        this.setState({ error: true });
        console.log('error during call : ' + err);
      });
  }

  handlePageClick = (data) => {
    console.log(data);
    this.setState({ currentPage: data.selected });
    document.activeElement.blur();

    const elmnt = document.getElementById('intramuros_news');
    if (elmnt) {
      elmnt.scrollIntoView(true);
    }
  };

  selectTypeNewsFilter(option) {
    this.setState({ typeSelected: option, currentPage: 0 });
  }

  render() {
    const { error, fetched, currentPage } = this.state;
    const showFilters = window.showFiltersNews
    let { news } = this.state;
    const { typeSelected } = this.state;

    if (!news) {
      if (error || fetched) {
        return null;
      }
      return <Loader />;
    }

    let divStyle = { overflowY: 'auto', overflowX: 'hidden' };
    if (window.maxHeightNews > 0) {
      divStyle = { ...divStyle, maxHeight: parseInt(window.maxHeightNews, 10) };
    }
    if (window.backgroundColorNews) {
      divStyle = { ...divStyle, backgroundColor: window.backgroundColorNews };
    }

    const typeNewsFilterList = calculateNewsFilters(news);

    if (typeSelected) {
      news = getNewsListFiltered(
        news,
        typeSelected,
      );
      // console.log("News filtered = ", news)
    }

    const numberOfNews = size(news);
    const numberOfPages = Math.ceil(numberOfNews / NUMBER_OF_ITEMS_PER_PAGE);
    // console.log('number of pages = ' + numberOfPages);

    //  Calculate list of current page
    if (numberOfPages > 1) {
      news = slice(
        news,
        currentPage * NUMBER_OF_ITEMS_PER_PAGE,
        currentPage * NUMBER_OF_ITEMS_PER_PAGE + NUMBER_OF_ITEMS_PER_PAGE,
      );
    }

    const customTypography = window.forceFont
      ? { fontFamily: ['Jost', 'sans-serif'].join(',') }
      : { fontFamily: 'inherit' };

    const themeOptions = {
      ...defaultThemeOption,
      typography: customTypography,
    };

    const theme = muiTheme(themeOptions);

    if (numberOfNews < 1) {
      return (
        <div style={divStyle}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <Typography
                align="center"
                style={{
                  fontWeight: 'bold',
                  marginTop: 20,
                  marginBottom: 20,
                  color: '#333',
                }}
              >
                {'Aucune publication'}
              </Typography>
            </ThemeProvider>
          </StyledEngineProvider>
        </div>
      );
    }

    if (DISPLAY_MODE === 'slick') {
      return (
        <div
          style={{
            marginRight: '3em',
            marginLeft: '3em',
            marginTop: '3em',
          }}
        >
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              {showFilters ? (
                <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  marginBottom: 25,
                }}
                >
                  {size(typeNewsFilterList) > 1 ? (
                    <SelectorNewsType
                      options={typeNewsFilterList}
                      selectOption={(option) => this.selectTypeNewsFilter(option)}
                    />
                  ) : null}
                </Grid>
              ) : null }
              <Grid container justifyContent="center" style={{ backgroundColor: window.backgroundColorNews }}>
                <Grid item xs={9}>
                  <NewsSlider
                    news={news}
                    theme={theme}
                  />
                </Grid>
              </Grid>
            </ThemeProvider>
          </StyledEngineProvider>
        </div>
      );
    }

    return (
      <div style={divStyle}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            {showFilters ? (
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  marginBottom: 25,
                }}
              >
                {size(typeNewsFilterList) > 1 ? (
                  <SelectorNewsType
                    options={typeNewsFilterList}
                    selectOption={(option) => this.selectTypeNewsFilter(option)}
                  />
                ) : null}
              </Grid>
            ) : null}
            {news.map((theNews) => (
              <NewsCard news={theNews} key={theNews.id} />
            ))}
            {numberOfPages > 1 ? (
              <Grid container justifyContent="center" style={{ marginTop: 30 }}>
                <ReactPaginate
                  previousLabel="<"
                  nextLabel=">"
                  breakLabel="..."
                  breakClassName="break-me"
                  pageCount={numberOfPages}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={1} // saut de page: quand on clique sur les ...
                  onPageChange={this.handlePageClick}
                  containerClassName="pagination"
                  subContainerClassName="pages pagination"
                  activeClassName="active"
                  forcePage={currentPage}
                />
              </Grid>
            ) : null}
          </ThemeProvider>
        </StyledEngineProvider>
      </div>
    );
  }
}

News.propTypes = {
  cityId: PropTypes.number,
  aggloId: PropTypes.number,
  cityINSEE: PropTypes.string,
  aggloSIREN: PropTypes.string,
};

export default News;
