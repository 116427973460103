// @flow

import {
  format,
  differenceInCalendarDays,
  getMonth,
  getYear,
  parseISO,
  isToday,
  isAfter,
  isBefore,
  eachMonthOfInterval,
  differenceInCalendarMonths
} from 'date-fns';
import frLocale from 'date-fns/locale/fr';
import {
  toUpper,
  upperFirst,
  orderBy,
  isEmpty,
  filter,
  uniqBy,
  reduce,
  forEach,
  find,
  deburr,
} from 'lodash';

// Date will look like this: VEN. 18 NOVEMBRE
export const formatDateLong = (date) => {
  try {
    const formatedDate = format(parseISO(date), 'iii d LLLL', {
      locale: frLocale,
    });
    return toUpper(formatedDate);
  } catch (err) {
    console.error(err);
  }
  return `${date}`;
};

// Date will look like this: Vendredi 18 novembre 2020
export const formatDateLongFirstLetterUpper = (date) => {
  try {
    const formatedDate = format(parseISO(date), 'iiii d LLLL yyyy', {
      locale: frLocale,
    });
    return upperFirst(formatedDate);
  } catch (err) {
    console.error(err);
  }
  return `${date}`;
};

// Date will look like this: Novembre 2018
export const formatDateMonthYear = (date) => {
  try {
    const formatedDate = format(parseISO(date), 'LLLL yyyy', {
      locale: frLocale,
    });
    return upperFirst(formatedDate);
  } catch (err) {
    console.error(err);
  }
  return `${date}`;
};

// Date will look like this: 2018-02
export const formatMonthYearId = (date) => {
  try {
    const formatedDate = format(parseISO(date), 'yyyy-LL', {
      locale: frLocale,
    });
    return formatedDate;
  } catch (err) {
    console.error(err);
  }
  return `${date}`;
};

export const formatDateCalendar = (date) => {
  try {
    const formatedDate = format(parseISO(date), 'yyyyLLdd', {
      locale: frLocale,
    });
    return formatedDate;
  } catch (err) {
    console.error(err);
  }
  return `${date}`;
};

// Date will look like this: Nov.
export const formatShortMonth = (date) => {
  try {
    const formatedDate = format(parseISO(date), 'LLL', {
      locale: frLocale,
    });
    return upperFirst(formatedDate);
  } catch (err) {
    console.error(err);
  }
  return `${date}`;
};

// Date will look like this: Mer.
export const formatShortDay = (date) => {
  try {
    const formatedDate = format(parseISO(date), 'iii', {
      locale: frLocale,
    });
    return upperFirst(formatedDate);
  } catch (err) {
    console.error(err);
  }
  return `${date}`;
};

export const numberOfDays = (endDate, startDate) => {
  try {
    return differenceInCalendarDays(parseISO(endDate), parseISO(startDate));
  } catch (err) {
    console.error(err);
  }
  return null;
};

export const filterEventList = (eventList, month, year, catSelected) => {
  if (isEmpty(eventList)) {
    return [];
  }
  let dataSource = eventList;

  dataSource = filter(dataSource, (obj) => {
    let valid = false;

    // Filter by mmonth and category
    if (month > 0 && year > 0) {
      const monthsInterval = eachMonthOfInterval({
        start: parseISO(obj.start_date),
        end: parseISO(obj.end_date),
      });

      // Parcours les mois de l'évenement et regarde si ça match
      if (monthsInterval) {
        monthsInterval.forEach((date) => {
          if (getMonth(date) + 1 === month && getYear(date) === year) {
            valid = true;
          }
        });
      }
    } else {
      valid = true;
    }

    // filtre par catégorie
    if (catSelected > 0 && valid) {
      valid = obj.category === catSelected;
    }

    return valid;
  });

  dataSource = orderBy(dataSource, ['start_date'], ['asc']);
  return dataSource;
};

export const GenerateDateFilterListDataSource = (eventsList) => {
  const now = new Date();
  let dataSource = eventsList;
  dataSource = reduce(
    dataSource,
    (acc, next) => {
      if (differenceInCalendarMonths(parseISO(next.start_date), now) >= 0) {
        acc.push({
          key: formatMonthYearId(next.start_date),
          label: formatDateMonthYear(next.start_date),
        });
      }
      acc.push({
        key: formatMonthYearId(next.end_date),
        label: formatDateMonthYear(next.end_date),
      });
      return acc;
    },
    [],
  );
  dataSource = uniqBy(dataSource, 'key');
  dataSource = orderBy(dataSource, ['key'], ['asc']);
  return dataSource;
};

export const GenerateCategoryIdListDataSource = (eventsList) => {
  let dataSource = eventsList;
  dataSource = reduce(
    dataSource,
    (acc, next) => {
      if (next.category && acc.indexOf(next.category) < 0) {
        acc.push(next.category);
      }
      return acc;
    },
    [],
  );
  return dataSource;
};

export const GenerateCategoryFilterListDataSource = (
  categoriesIds,
  categoriesList,
) => {
  let dataSource = [];
  forEach(categoriesIds, (ID) => {
    const cat = find(categoriesList, { id: ID });
    if (cat && cat.name) {
      dataSource.push({ id: ID, name: cat.name });
    }
  });

  dataSource = orderBy(dataSource, [(item) => deburr(item.name)], 'asc');
  return dataSource;
};

export const calculateEndingSurvey = (endDate) => {
  try {
    if (!endDate) return '';

    if (isToday(parseISO(endDate))) {
      return "aujourd'hui";
    }
    const countDownDays = differenceInCalendarDays(
      new Date(endDate),
      new Date(),
    );
    return 'dans ' + countDownDays + (countDownDays > 1 ? ' jours' : ' jour');
  } catch (err) {
    console.error(err);
  }
  return `${endDate}`;
};

export const isSurveyInProgress = (survey) => {
  if (!survey) {
    return false;
  }
  return (
    isAfter(parseISO(survey.end_date), new Date()) ||
    isToday(parseISO(survey.end_date))
  );
};

export const formatDateAll = (date) => {
  try {
    const formatedDate = format(parseISO(date), 'iiii d LLLL yyyy', {
      locale: frLocale,
    });
    return formatedDate;
  } catch (err) {
    console.error(err);
  }
  return `${date}`;
};

export const isPast = (date) => {
  if (!date) {
    return false;
  }
  return isBefore(parseISO(date), new Date());
};

// get month
export const getDateMonth = (date) => {
  try {
    const formatedDate = format(parseISO(date), 'LLLL', {
      locale: frLocale,
    });
    return upperFirst(formatedDate);
  } catch (err) {
    console.error(err);
  }
  return `${date}`;
};

// get day
export const getDateDay = (date) => {
  try {
    const formatedDate = format(parseISO(date), 'dd', {
      locale: frLocale,
    });
    return upperFirst(formatedDate);
  } catch (err) {
    console.error(err);
  }
  return `${date}`;
};

// get day string
export const getDateStringDay = (date) => {
  try {
    const formatedDate = format(parseISO(date), 'iiii', {
      locale: frLocale,
    });
    return upperFirst(formatedDate);
  } catch (err) {
    console.error(err);
  }
  return `${date}`;
};

export const isCurrentEvent = (startDate, endDate) => {
  try {
    const now = new Date();
    const startDateFormated = parseISO(startDate);
    const endDateFormated = parseISO(endDate);
    return (
      (isAfter(now, startDateFormated) || isToday(startDateFormated)) &&
      (isAfter(endDateFormated, now) || isToday(endDateFormated))
    );
  } catch (err) {
    console.error(err);
  }

  return false;
};